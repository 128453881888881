/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
/* eslint-disable no-unreachable */
/* eslint-disable prettier/prettier */
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import localforage from 'localforage';
import {
  BorderStyle,
  BottomMenuType,
  Categories,
  DrawOptions,
  ElementTypes,
  RenderMode,
  ShapeTypes,
  SubCategories,
} from "../definitions/types";
import { useStyles } from "../views/EditorView/Editor/2D/styles";
import { fabric } from "fabric";
import {
  ActiveSelection,
  Canvas,
  Object,
  Text,
} from "fabric/fabric-impl";
import { GameManager } from "../views/EditorView/Editor/3D";
import {
  ObjectConfig,
  TextOptions,
} from "../definitions/types/index";
import { SideTypes } from "../views/EditorView/Editor/utilities";
import "./marker-brush";
import { pentagramPoints, starPolygonPoints } from "./utils";
import useWindowDimensions from "hooks/useWindowDimensions";

type SideData = {
  [sideId in SideTypes]: { version: string; objects: Object[] } | undefined;
};
type ImgSideData = {
  [sideId in SideTypes]: string;
};
const container = createRef<HTMLCanvasElement>();
export const MaxLineHeight = 3;
export const MinLineHeight = 1.2;

export const DefualtSelectedObjectsConfig: ObjectConfig = {
  isBold: false,
  isItalic: false,
  color: "#000000",
  borderColor: "#000000",
  borderWidth: 2,
  borderRadius: 0,
  dashedBorders: false,
  fontSize: 18,
  fontFamily: "arial",
  lineHeight: MinLineHeight,
  textAlign: "left",
  opacity: 1,
  text: "",
};

const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const useEditorActions = () => {
  const copiedObjectRef = useRef(null);
  const undoStack = useRef([]); // Stack to store states for undo
  const redoStack = useRef([]); // Stack to store states for redo
  const { width, height } = useWindowDimensions();
  const [selectedRenderMode, setSelectedRenderMode] = useState<RenderMode>(
    "2DMODE",
  );
  const [isCanvasReady, setIsCanvasReady] = useState(false);

  const [isFirstUse, setIsFirstUse] = useState<boolean>(true);
  const [canvasColor, setCanvasColor] = useState<string>("#FFFFFF");
  const [fabricCanvas, setFabricCanvas] = useState<Canvas>();
  const [gameManager, setGameManager] = useState<GameManager>();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedSide, setSelectedSide] = useState<keyof typeof SideTypes>(
    "FRONT",
  );
  const [sidesData, setSideData] = useState<SideData>({
    BACK: undefined,
    FRONT: undefined,
    LEFT: undefined,
    RIGHT: undefined,
    Back: undefined,
  });

  //tmp
  const [imgsSidesData, setimgsSideData] = useState<ImgSideData>({
    BACK: "",
    FRONT: "",
    LEFT: "",
    RIGHT: "",
    Back: "",
  });

  const [selectedObjectsConfig, setSelectedObjectsConfig] = useState<
    ObjectConfig
  >(DefualtSelectedObjectsConfig);
  const [elementType, selectedElementType] = useState<
    ElementTypes | undefined
  >();
  const [selectedCategory, setSelectedCategory] = useState<
    Categories | undefined
  >();
  const [bottomMenu, setBottomMenu] = useState<BottomMenuType | undefined>(
    (width ?? 0) > 700 && (height ?? 0) > 450
      ? "HorizontalMenu"
      : "CircularMenu",
  );
  const [showRightMenu, setShowRightMenu] = useState<boolean>(true);
  const [isFabricActonsReady, setIsFabricActonsReady] = useState<boolean>(
    false,
  );

  const [selectedSubCategory, setSelectedSubCategory] = useState<
    SubCategories | undefined
  >();

  // cache user uploaded images locally
  localforage.config({
    name: "ownly",
    storeName: "images"
  })

  const saveImage = async (key: any, imageBlob: any) => {
    try {
      await localforage.setItem(key, imageBlob);
      console.log('Image saved successfully.');
    } catch (error) {
      console.error('Error saving image:', error);
    }
  };

  const fabricCanvasRef = useRef(fabricCanvas);

  const classes = useStyles();

  const updateSelectedObjectsConfig = useCallback(
    (tmpFabricCanvas?: Canvas) => {
      const fabricInstance = tmpFabricCanvas || fabricCanvas;
      if (!fabricInstance) {
        return;
      }

      const aObject = fabricInstance.getActiveObject();
      const objects = [];
      if (!aObject) {
        return;
      }
      if (aObject?.type === "activeSelection") {
        objects.push(...(aObject as ActiveSelection).getObjects());
      } else {
        objects.push(aObject);
      }
      // console.log('objects', objects);
      const newConfigs: ObjectConfig = {
        isBold: objects.every((obj) => (obj as Text).fontWeight === "bold"),
        isItalic: objects.every((obj) => (obj as Text).fontStyle === "italic"),
        color: objects[0].fill?.toString() ||
          DefualtSelectedObjectsConfig.color,
        borderColor: objects[0].stroke?.toString() ||
          DefualtSelectedObjectsConfig.borderColor,
        borderWidth: objects[0].strokeWidth ||
          DefualtSelectedObjectsConfig.borderWidth,
        borderRadius: (objects[0] as fabric.Rect)?.rx ||
          DefualtSelectedObjectsConfig.borderRadius,
        dashedBorders: !!(objects[0] as fabric.Rect)?.strokeDashArray?.length ||
          DefualtSelectedObjectsConfig.dashedBorders,
        fontSize: (objects[0] as Text).fontSize ||
          DefualtSelectedObjectsConfig.fontSize,
        fontFamily: (objects[0] as Text).fontFamily ||
          DefualtSelectedObjectsConfig.fontFamily,
        lineHeight: (objects[0] as Text).lineHeight ||
          DefualtSelectedObjectsConfig.lineHeight,
        textAlign: (objects[0] as Text).textAlign ||
          DefualtSelectedObjectsConfig.textAlign,
        opacity: (objects[0] as Text).opacity ||
          DefualtSelectedObjectsConfig.opacity,
        text: (objects[0] as Text).text || DefualtSelectedObjectsConfig.text,
      };
      setSelectedObjectsConfig(newConfigs);
    },
    [fabricCanvas],
  );

  const handleUndo = useCallback(() => {
    if (!fabricCanvas) {
      console.warn("No fabric instance available");
      return;
    }

    if (undoStack.current.length > 0) {
      const lastState = undoStack.current.pop();
      //@ts-ignore
      redoStack.current.push(fabricCanvas.toJSON());

      fabricCanvas.loadFromJSON(lastState, () => {
        fabricCanvas.renderAll();
      });
    } else {
      console.log("Undo stack is empty");
    }
  }, [fabricCanvas]);

  const handleRedo = useCallback(() => {
    if (!fabricCanvas) {
      console.warn("No fabric instance available");
      return;
    }

    if (redoStack.current.length > 0) {
      const nextState = redoStack.current.pop();
      //@ts-ignore
      undoStack.current.push(fabricCanvas.toJSON());

      fabricCanvas.loadFromJSON(nextState, () => {
        fabricCanvas.renderAll();
      });
    } else {
      console.log("Redo stack is empty");
    }
  }, [fabricCanvas]);

  const onSetFirstUse = useCallback((isFirstUse: boolean) => {
    setIsFirstUse(isFirstUse);
  }, []);

  const addImageToCanvas = useCallback(
    (imageSrc: string, opts: { left: number; top: number }, canvas: Canvas) => {
      const { left, top } = opts;
      const imgElement = new Image();
      imgElement.src = imageSrc;

      imgElement.onload = () => {
        const fabricImage = new fabric.Image(imgElement, {
          scaleX: 0.25,
          scaleY: 0.25,
          left: left - 115,
          top: top - 80,
        });
        canvas.add(fabricImage);
        canvas.requestRenderAll();
      };
    },
    [],
  );

  const saveState = useCallback(() => {
    if (!fabricCanvas) {
      return;
    }
    const json = fabricCanvas.toJSON();
    // @ts-ignore
    undoStack.current.push(json);
  }, [fabricCanvas]);

  const addIconToCanvas = useCallback(
    (canvas: any, icon: any, left: number, top: number) => {
      const size = 15;
      if (!canvas) {
        console.log("No canvas provided");
        return;
      }
      canvas.fillStyle = "#ffffff";
      canvas.fillRect(left - 10, top - 10, 20, 20); // Reduced control size
      canvas.save();
      canvas.translate(left, top);
      canvas.drawImage(icon, -size / 2, -size / 2, size, size);
      canvas.restore();
      console.log("added image");
    },
    [fabricCanvas],
  );

  useEffect(() => {
    fabricCanvasRef.current = fabricCanvas;
    // checks if any design is cached in local storage
    // this will be used in case if the user designed something without being authenticated
    const tempDesign = localStorage.getItem("temp_design");
    if (tempDesign) {
      const tempDesignData = JSON.parse(tempDesign);
      setSideData(tempDesignData.sidesData);
      setimgsSideData(tempDesignData.imgsSidesData);
    }
  }, [fabricCanvas]);

  useEffect(() => {
    if (isCanvasReady && fabricCanvas) {
      const Undo = () => {
        console.log("Undo triggered");
        handleUndo();
      };

      const handleKeyDown = (event: any) => {
        if ((event.metaKey || event.ctrlKey) && event.key === "z") {
          handleUndo();
        } else if ((event.metaKey || event.ctrlKey) && event.key === "y") {
          handleRedo();
        }
      };

      const undoButton = document.getElementById("undoButton");
      if (undoButton) {
        undoButton.addEventListener("click", Undo);
      }
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        if (undoButton) {
          undoButton.removeEventListener("click", Undo);
        }
        window.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [isCanvasReady, fabricCanvas, handleUndo]);

  const getSelectedElement = useCallback(
    (tmpFabricCanvas?: Canvas) => {
      const fabricInstance = tmpFabricCanvas || fabricCanvas;
      if (!fabricInstance) {
        return null;
      }
      const aObject = fabricInstance.getActiveObject();
      const objects = [];
      if (!aObject) {
        return null;
      }
      return aObject;
    },
    [fabricCanvas],
  );

  const onSelectElement = useCallback(
    (tmpFabricCanvas?: Canvas) => {
      const aObject = getSelectedElement(tmpFabricCanvas);
      if (
        !aObject ||
        (tmpFabricCanvas as any)["category"] === "PrintingTypes"
      ) {
        return;
      }

      // removing assigning back category as it ruins user experience
      // to select the category twice upon de-selection of selected element
      if (aObject?.type !== "activeSelection") {
        selectedElementType(aObject?.type as ElementTypes);
        if (aObject?.type) {
          setTimeout(() => {
            switch (aObject?.type as ElementTypes) {
              case "i-text":
                (width ?? 0) < 700 || (height ?? 0) < 450
                  ? setShowRightMenu(false)
                  : setShowRightMenu(true);
                setSelectedCategory("Texts");
                bottomMenuVisibility("HorizontalMenu");
                break;
              case "image":
                (width ?? 0) < 700 || (height ?? 0) < 450
                  ? setShowRightMenu(false)
                  : setShowRightMenu(true);
                setSelectedCategory("Uploads");
                break;
              case "rect":
              case "circle":
              case "triangle":
              case "polygon":
                (width ?? 0) < 700 || (height ?? 0) < 450
                  ? setShowRightMenu(false)
                  : setShowRightMenu(true);
                setSelectedCategory("Graphics");
                bottomMenuVisibility("HorizontalMenu");
                break;
              case "path":
                if ((aObject as any)["customId"]) {
                  (width ?? 0) < 700 || (height ?? 0) < 450
                    ? setShowRightMenu(false)
                    : setShowRightMenu(true);
                  setSelectedCategory("Graphics");
                  bottomMenuVisibility("HorizontalMenu");
                } else {
                  (width ?? 0) < 700 || (height ?? 0) < 450
                    ? setShowRightMenu(false)
                    : setShowRightMenu(true);
                  setSelectedCategory("Draw");
                  bottomMenuVisibility("HorizontalMenu");
                }
                break;
              default:
                setSelectedCategory(undefined);
                break;
            }
          }, 350);
        }
        return;
      }
    },
    [getSelectedElement],
  );

  useEffect(() => {
    if (fabricCanvas) {
      fabricCanvas.on("selection:cleared", (e) => {
        const { deselected } = e;
        if (deselected) {
          setSelectedObjectsConfig(DefualtSelectedObjectsConfig);
        }
        selectedElementType(undefined);
        if (selectedCategory === "Filters") {
          setSelectedCategory(undefined);
        }
      });
    }

    if (fabricCanvas) {
      fabricCanvas.requestRenderAll();
    }
  }, [fabricCanvas, isFabricActonsReady, selectedCategory]);

  const onInit2DEditor = useCallback(() => {
    // eslint-disable-next-line no-underscore-dangle
    const containerInstance = container.current;
    if (!containerInstance) {
      return;
    }
    setIsFabricActonsReady(false);
    const { clientHeight, clientWidth } = containerInstance;
    const grid = 50;
    const lines = [];

    sidesData[selectedSide];

    const fabricCanvasInstance = new fabric.Canvas("canvas", {
      backgroundColor: "rgba(255, 255, 255, 1)",
      preserveObjectStacking: true,
    });

    fabricCanvasInstance.setDimensions({
      width: clientWidth,
      height: clientHeight,
    });

    if (sidesData[selectedSide]) {
      fabricCanvasInstance.loadFromJSON(sidesData[selectedSide], () => {
        fabricCanvasInstance.renderAll();
      });
    }

    const undoButton = document.getElementById("undoButton");
    if (undoButton) {
      undoButton.addEventListener("click", handleUndo);
    }

    const redoButton = document.getElementById("redoButton");
    if (redoButton) {
      redoButton.addEventListener("click", handleRedo);
    }

    const handleDelete = () => {
      const activeObject = fabricCanvasInstance.getActiveObject();
      if (
        // @ts-ignore
        activeObject &&
        // @ts-ignore
        activeObject._objects &&
        // @ts-ignore
        activeObject._objects.length > 0
      ) {
        // @ts-ignore
        activeObject._objects.forEach((obj: any) => {
          fabricCanvasInstance.remove(obj);
        });
      } else if (activeObject) {
        console.log(activeObject);
        fabricCanvasInstance.remove(activeObject);
      }
      setSelectedObjectsConfig(DefualtSelectedObjectsConfig);
      fabricCanvasInstance.renderAll();
    };

    const handleCopy = () => {
      const activeObject = fabricCanvasInstance.getActiveObject();
      if (activeObject) {
        activeObject.clone((cloned: any) => {
          copiedObjectRef.current = cloned; // Store the cloned object in the ref
        });
      }
    };

    const handlePaste = () => {
      if (copiedObjectRef.current) {
        // @ts-ignore
        copiedObjectRef.current.clone((cloned: any) => {
          cloned.set({
            // @ts-ignore
            left: (copiedObjectRef?.current?.left ?? 0) + 10,
            // @ts-ignore
            top: (copiedObjectRef?.current?.top ?? 0) + 10,
            evented: true,
          });
          if (cloned.type === "activeSelection") {
            // Active selection needs a reference to the canvas
            cloned.canvas = fabricCanvasInstance;
            cloned.forEachObject((obj: any) => fabricCanvasInstance.add(obj));
            cloned.setCoords();
          } else {
            fabricCanvasInstance.add(cloned);
          }
          fabricCanvasInstance.setActiveObject(cloned);
          fabricCanvasInstance.renderAll();
          copiedObjectRef.current = cloned; // Update the copied object reference
        });
      }
    };

    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "c") {
        handleCopy();
      } else if ((event.metaKey || event.ctrlKey) && event.key === "v") {
        handlePaste();
      } else if (event.key === "Delete") {
        handleDelete();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    function deleteObject(
      eventData: any,
      transform: any,
      x: number,
      y: number,
    ) {
      const { target } = transform;
      console.log("target here: ", target);
      if (!target) {
        console.error("Target is undefined");
        return false;
      }

      const { canvas } = target;
      if (!canvas) {
        console.error("Canvas is undefined");
        return false;
      }

      if (target.type === "activeSelection" && target.getObjects) {
        console.log("target: ", target);
        const objects = target.getObjects();
        if (objects && objects.length > 0) {
          objects.forEach((child: any) => {
            canvas.remove(child);
          });
          canvas.discardActiveObject();
        }
      } else if (target !== undefined) {
        canvas.remove(target);
      } else {
        console.log("target is undefined");
      }

      canvas.requestRenderAll();

      return false;
    }

    function cloneObject(eventData: any, transform: any, x: number, y: number) {
      const { target } = transform;
      const { canvas } = target;
      if (canvas) {
        target.clone((cloned: any) => {
          if (!canvas) {
            return;
          }

          if (target.type === "activeSelection") {
            (canvas && (canvas as Canvas))
              .getActiveObject()
              .clone((clonedGroup: any) => {
                clonedGroup.clone((clonedObj: any) => {
                  canvas.discardActiveObject();
                  clonedObj.set({
                    left: clonedObj.left + 10,
                    top: clonedObj.top + 10,
                    evented: true,
                  });
                  clonedObj.canvas = canvas;
                  clonedObj.forEachObject((obj: any) => {
                    canvas.add(obj);
                  });
                  clonedObj.setCoords();
                  clonedGroup.top += 10;
                  clonedGroup.left += 10;
                  canvas.setActiveObject(clonedObj);
                });
              });
          } else {
            cloned.left += 10;
            cloned.top += 10;
            canvas.add(cloned);
            canvas.setActiveObject(cloned);
          }
          canvas.requestRenderAll();
        });
      }
      return false;
    }

    function aboveObject(eventData: any, transform: any) {
      const { target } = transform;
      fabricCanvasInstance.bringForward(target);
      if (target.icons) {
        target.icons.forEach(({ icon }: { icon: any }) =>
          fabricCanvasInstance.remove(icon)
        );
        target.icons = [];
      }
      fabricCanvasInstance.renderAll();
      return false;
    }

    function aboveAllObjects(eventData: any, transform: any) {
      const { target } = transform;
      fabricCanvasInstance.bringToFront(target);
      target.showGraphicsSubMenu = false;
      if (target.icons) {
        target.icons.forEach(({ icon }: { icon: any }) =>
          fabricCanvasInstance.remove(icon)
        );
        target.icons = [];
      }
      fabricCanvasInstance.renderAll();
      return false;
    }

    function behindObject(eventData: any, transform: any) {
      const { target } = transform;
      fabricCanvasInstance.sendBackwards(target);
      // sendToBack(myObject)
      target.showGraphicsSubMenu = false;
      if (target.iconGroup) {
        fabricCanvasInstance.remove(target.iconGroup);
        target.iconGroup = null;
      }
      // Remove individual icons
      if (target.icons) {
        target.icons.forEach(({ icon }: { icon: any }) =>
          fabricCanvasInstance.remove(icon)
        );
        target.icons = null;
      }
      fabricCanvasInstance.renderAll();
      return false;
    }

    function behindAllObjects(eventData: any, transform: any) {
      const { target } = transform;
      fabricCanvasInstance.sendToBack(target);
      if (target.icons) {
        target.icons.forEach(({ icon }: { icon: any }) =>
          fabricCanvasInstance.remove(icon)
        );
        target.icons = [];
      }
      fabricCanvasInstance.renderAll();
      return false;
    }

    //
    const arrowDownImg = document.createElement("img");
    arrowDownImg.src =
      `${window.location.origin}/assets/icons/actions/moveIcon.svg`;

    const deleteImg = document.createElement("img");
    deleteImg.src =
      `${window.location.origin}/assets/icons/actions/removeIcon.svg`;

    const cloneImg = document.createElement("img");
    cloneImg.src =
      `${window.location.origin}/assets/icons/actions/duplicateIcon.svg`;

    const sendBackwardImg = document.createElement("img");
    sendBackwardImg.src =
      `${window.location.origin}/assets/icons/actions/sendBackward.svg`;

    const bringForwardImg = document.createElement("img");
    bringForwardImg.src =
      `${window.location.origin}/assets/icons/actions/bringForward.svg`;

    const sendToBackImg = document.createElement("img");
    sendToBackImg.src =
      `${window.location.origin}/assets/icons/actions/sendToBack.svg`;

    const bringToFrontImg = document.createElement("img");
    bringToFrontImg.src =
      `${window.location.origin}/assets/icons/actions/bringToFront.svg`;

    fabric.Object.prototype.controls.downArrowControl = new fabric.Control({
      x: -0.1,
      y: -0.5,
      offsetY: -16,
      offsetX: 42, // Adjusted for gap
      cursorStyle: "pointer",
      mouseUpHandler: (eventData: any, transform: any) => {
        const { target } = transform;
        const { canvas } = target;
        const pointer = canvas.getPointer(eventData.e);
        const iconWidth = 60; // Approximate width of each icon group
        const iconSpacing = 20; // Space between icons
        const totalIconsWidth = iconWidth * 4 + iconSpacing * 3;

        // Get pointer coordinates
        const top = pointer.y + 20;
        const left = pointer.x - 150;

        let icons: { icon: fabric.Group; offsetX: number; offsetY: number }[] =
          [];

        if (target.showGraphicsSubMenu === false) {
          target.showGraphicsSubMenu = true;

          // Helper function to create a fabric.Image with label
          const createIconWithLabel = (
            imgElement: HTMLImageElement,
            left: number,
            top: number,
            label: string,
          ) => {
            const icon = new fabric.Image(imgElement, {
              left: left,
              top: top,
              scaleX: 0.9,
              scaleY: 0.9,
              selectable: false,
              hasControls: false,
              hasBorders: false,
              evented: true,
            });

            const text = new fabric.Text(label, {
              left: left + 5,
              top: top + icon.getScaledHeight() + 5,
              fontSize: 9,
              fontFamily: "arial",
              originX: "center",
              selectable: false,
              hasControls: false,
              hasBorders: false,
              evented: true,
            });

            return new fabric.Group([icon, text], {
              left: left,
              top: top,
              selectable: false,
              hasControls: false,
              hasBorders: false,
              evented: true,
            });
          };

          const sendBackwardImgObj = createIconWithLabel(
            sendBackwardImg,
            0,
            0,
            "Send Backward",
          );
          const bringForwardImgObj = createIconWithLabel(
            bringForwardImg,
            80,
            0,
            "Bring Forward",
          );
          const bringToFrontImgObj = createIconWithLabel(
            bringToFrontImg,
            160,
            0,
            "Bring to Front",
          );
          const sendToBackImgObj = createIconWithLabel(
            sendToBackImg,
            240,
            0,
            "Send to Back",
          );

          // Create a single background for all icons
          const backgroundWidth = totalIconsWidth + iconSpacing * 2; // Add padding on both sides
          const backgroundHeight = 70; // Increased height for better vertical centering
          const background = new fabric.Rect({
            left: 0,
            top: 0,
            width: backgroundWidth,
            height: backgroundHeight,
            fill: "white",
            rx: 3, // Rounded corners
            ry: 3,
            selectable: false,
            hasControls: false,
            hasBorders: false,
            evented: false,
          });

          // Create a group with the background and all icons
          const iconGroup = new fabric.Group(
            [
              background,
              sendBackwardImgObj,
              bringForwardImgObj,
              bringToFrontImgObj,
              sendToBackImgObj,
            ],
            {
              left: left,
              top: top,
              selectable: false,
              hasControls: false,
              hasBorders: false,
              evented: true,
            },
          );
          const centerY = backgroundHeight / 2;
          iconGroup
            .getObjects()
            .slice(1)
            .forEach((obj, index) => {
              obj.set({
                left: iconSpacing + index * (iconWidth + iconSpacing) - 160,
                top: -15,
              });
            });
          canvas.add(iconGroup);
          target.iconGroup = iconGroup; // Attach icon group to the target for easy access
          icons = [
            {
              icon: sendBackwardImgObj,
              offsetX: left - target.left,
              offsetY: top - target.top,
            },
            {
              icon: bringForwardImgObj,
              offsetX: left + 80 - target.left,
              offsetY: top - target.top,
            },
            {
              icon: bringToFrontImgObj,
              offsetX: left + 160 - target.left,
              offsetY: top - target.top,
            },
            {
              icon: sendToBackImgObj,
              offsetX: left + 240 - target.left,
              offsetY: top - target.top,
            },
          ];

          // Define mouse down handlers for each icon
          sendBackwardImgObj.on("mousedown", (event: any) => {
            behindObject(event, transform);
            saveState();
          });
          sendToBackImgObj.on("mousedown", (event: any) => {
            behindAllObjects(event, transform);
            saveState();
          });
          bringForwardImgObj.on("mousedown", (event: any) => {
            aboveObject(event, transform);
            saveState();
          });
          bringToFrontImgObj.on("mousedown", (event: any) => {
            aboveAllObjects(event, transform);
            saveState();
          });
          // Add icons to canvas and to the parent object
          icons.forEach(({ icon }) => canvas.add(icon));
          target.icons = icons; // Attach icons to the target for easy access

          // Add event listener for moving parent object
          target.on("moving", () => {
            const { left, top } = target;
            console.log("this is running upon moving")
            iconGroup.set({
              left: left + 35,
              top: top,
            });
            iconGroup.setCoords(); // Update the position of the icon group
            canvas.renderAll();

            // perform check if gizmo is at top
            checkGizmoPosition(iconGroup, canvas);

          });
        } else {
          // Remove icon group if submenu is already showing
          target.showGraphicsSubMenu = false;
          if (target.iconGroup) {
            canvas.remove(target.iconGroup);
            target.iconGroup = null;
          }
          // Remove individual icons
          if (target.icons) {
            target.icons.forEach(({ icon }: { icon: any }) =>
              canvas.remove(icon)
            );
            target.icons = null;
          }
          canvas.renderAll();
        }
        return false;
      },

      render: function (ctx, left, top, styleOverride, fabricObject) {
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(left - 12, top - 10, 22, 22); // Reduced control size
        renderIcon(arrowDownImg)(ctx, left, top, styleOverride, fabricObject);
        saveState();
      },
      sizeX: 8, // Adjusted size
      sizeY: 16, // Reduced size
    });

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: -0.1,
      y: -0.5,
      offsetY: -16,
      offsetX: 82, // Increased gap between controls
      cursorStyle: "pointer",
      mouseUpHandler: deleteObject,
      render: function (ctx, left, top, styleOverride, fabricObject) {
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(left - 12, top - 10, 22, 22); // Reduced control size
        renderIcon(deleteImg)(ctx, left, top, styleOverride, fabricObject);
        saveState();
      },
      sizeX: 16, // Reduced size
      sizeY: 16, // Reduced size
    });

    fabric.Object.prototype.controls.cloneControl = new fabric.Control({
      x: -0.1,
      y: -0.5,
      offsetY: -16,
      offsetX: 62, // Adjusted for gap
      cursorStyle: "pointer",
      mouseUpHandler: cloneObject,
      render: function (ctx, left, top, styleOverride, fabricObject) {
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(left - 12, top - 10, 22, 22); // Reduced control size
        renderIcon(cloneImg)(ctx, left, top, styleOverride, fabricObject);
        saveState();
      },
      sizeX: 16, // Reduced size
      sizeY: 16, // Reduced size
    });

    fabricCanvasInstance.on("selection:created", (e) => {
      // @ts-ignore
      if (e.deselected) {
        const [target] = e.deselected;
        // @ts-ignore
        if (target && target.showGraphicsSubMenu) {
          // @ts-ignore
          target.showGraphicsSubMenu = false;
          // @ts-ignore
          if (target.iconGroup) {
            // @ts-ignore
            fabricCanvasInstance.remove(target.iconGroup);
            // @ts-ignore
            target.iconGroup = null;
          }
          // @ts-ignore
          if (target.icons) {
            // @ts-ignore
            target.icons.forEach(({ icon }) =>
              fabricCanvasInstance.remove(icon)
            );
            // @ts-ignore
            target.icons = null;
          }
          fabricCanvasInstance.renderAll();
        }
      }
      console.log("selected element");
      // if ((width ?? 0) < 700 && (height ?? 0) > 450) {
      setBottomMenu("CircularMenu");
      // }
      setShowRightMenu(false);
      updateSelectedObjectsConfig(fabricCanvasInstance);
      onSelectElement(fabricCanvasInstance);
    });
    fabricCanvasInstance.on("selection:updated", (e) => {
      updateSelectedObjectsConfig(fabricCanvasInstance);
      onSelectElement(fabricCanvasInstance);
    });
    fabricCanvasInstance.on("mouse:up", (e) => {
      if (
        fabricCanvasInstance.isDrawingMode &&
        (fabricCanvasInstance as any)["category"] === "Draw"
      ) {
        if (!fabricCanvasInstance.isDrawingMode) {
          fabricCanvasInstance.isDrawingMode = false;
        }
        onSetRightMenu(true);
        setShowRightMenu(true);
      }
    });

    // fabricCanvasInstance.on("selection:cleared", (e) => {
    //   console.log("cleared =====", e);
    //   const { deselected } = e;
    //   if (deselected) {
    //     setSelectedObjectsConfig(DefualtSelectedObjectsConfig);
    //   }
    //   selectedElementType(undefined);
    //   setSelectedCategory(undefined);
    // });
    fabricCanvasInstance.on("object:selected", (e) => {
      saveState();
      // console.log("selected =====", e);
      // const {deselected} = e;
      // if (deselected) {
      //   setSelectedObjectsConfig(DefualtSelectedObjectsConfig);
      // }
    });

    fabricCanvasInstance.on("selection:cleared", (e) => {
      console.log("clearinggggggg");
      // @ts-ignore
      if (e.deselected) {
        const [target] = e.deselected;
        if (target) {
          // @ts-ignore
          target.showGraphicsSubMenu = false;
          // @ts-ignore
          if (target.icons) {
            // @ts-ignore
            fabricCanvasInstance.remove(target.iconGroup);
            // @ts-ignore
            target.icons.forEach(({ icon }: { icon: any }) =>
              fabricCanvasInstance.remove(icon)
            );
            // @ts-ignore
            target.icons = null;
          }
        }
      }
      fabricCanvasInstance.renderAll();
      setSelectedCategory(undefined);
    });

    fabricCanvasInstance.on("object:added", saveState);
    fabricCanvasInstance.on("object:modified", saveState);
    fabricCanvasInstance.on("object:removed", saveState);

    // save initial state
    saveState();
    //events
    function handleDragEnter(e: DragEvent) {
      e.preventDefault();
      e.target && (e.target as HTMLElement).classList.add(classes.dragging);
      saveState();
    }
    //
    function handleDragOver(e: any) {
      if (e.preventDefault) {
        e.preventDefault(); // Necessary. Allows us to drop.
      }
      saveState();
      return false;
    }
    //
    const handleDrop = (e: any) => {
      e.preventDefault();
      const imageSrc = e.dataTransfer && e.dataTransfer.getData("text");
      addImageToCanvas(
        imageSrc || "",
        { left: e?.layerX, top: e.layerY },
        fabricCanvasInstance,
      );
      e.target && (e.target as HTMLElement).classList.remove(classes.dragging);
      saveState();
    };
    //
    function handleDragLeave(e: DragEvent) {
      e.preventDefault();
      e.target && (e.target as HTMLElement).classList.remove(classes.dragging);
    }

    function throttle(func: any, limit: any) {
      let lastFunc: any;
      let lastRan: any;
      return (...args: any) => {
        // Use rest parameters to capture arguments
        if (lastRan === false || lastRan === null) {
          // @ts-ignore
          func.apply(this, args); // Use 'this' directly without aliasing
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = setTimeout(() => {
            if (Date.now() - lastRan >= limit) {
              // @ts-ignore
              func.apply(this, args); // Use 'this' directly without aliasing
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      };
    }

    const handleMouseScroll = throttle((event: any) => {
      if (!fabricCanvasInstance) {
        return;
      }

      console.log("zooming");
      event.preventDefault();
      const delta = event.deltaY > 0 ? 0.9 : 1.1;
      const currentZoom = fabricCanvasInstance.getZoom();
      const newZoomLevel = Math.max(0.5, Math.min(currentZoom * delta, 4));

      adjustCanvasForZoom(newZoomLevel);
    }, 100);

    const adjustCanvasForZoom = throttle((zoomLevel: number) => {
      if (!fabricCanvasInstance) {
        return;
      }
      const originalWidth = fabricCanvasInstance.getWidth();
      const originalHeight = fabricCanvasInstance.getHeight();

      fabricCanvasInstance.setWidth(originalWidth * zoomLevel);
      fabricCanvasInstance.setHeight(originalHeight * zoomLevel);
      fabricCanvasInstance.setZoom(zoomLevel);
      fabricCanvasInstance.renderAll();
      console.log("zoom canvas");
    }, 100);

    let isDragging = false;
    let startX: any;
    let startY: any;
    let lastTap = 0;

    const onMouseDown = (event: any) => {
      if (event.button !== 2) {
        // Check if the right mouse button is clicked
        return;
      }

      isDragging = true;
      startX = event.clientX;
      startY = event.clientY;

      // Disable object selection during dragging
      fabricCanvasInstance.selection = false;
    };

    function checkGizmoPosition(iconGroup: any, canvas: any) {
      // Get the bounding rectangle of the iconGroup
      const gizmoBoundingRect = iconGroup.getBoundingRect(true, false);

      // Check if the top of the gizmo is at or above the canvas's top boundary
      if (gizmoBoundingRect.top <= 0) {
        console.log("Gizmo has reached the top of the canvas");

        // Optional: Adjust the gizmo's position to keep it within bounds
        // For example, set it to a minimum top value
        iconGroup.top = Math.max(iconGroup.top, 10); // 10px padding
        iconGroup.setCoords();
        canvas.renderAll();

        // You can also trigger additional logic here, such as disabling certain controls
      }

      // Similarly, you can add checks for other boundaries if needed
    }

    const onMouseMove = (event: any) => {
      if (!isDragging) {
        return;
      }
      const dx = event.clientX - startX;
      const dy = event.clientY - startY;

      // Update the canvas viewport
      fabricCanvasInstance.relativePan({
        x: dx,
        y: dy,
      });

      // Update the starting point for the next move
      startX = event.clientX;
      startY = event.clientY;
    };

    const onMouseUp = (event: any) => {
      if (event.button !== 2) {
        // Ensure we're handling the right mouse button
        return;
      }
      isDragging = false;
      // Re-enable object selection after dragging
      fabricCanvasInstance.selection = true;
    };

    const canvasContainer = document.getElementById("canvas-container");
    const resizeListener = () => handleResize(fabricCanvasInstance);
    if (canvasContainer) {
      canvasContainer.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });
      canvasContainer.addEventListener("dragenter", handleDragEnter);
      canvasContainer.addEventListener("dragover", handleDragOver, false);
      canvasContainer.addEventListener("drop", handleDrop, false);
      canvasContainer.addEventListener("dragleave", handleDragLeave);
      canvasContainer.addEventListener("wheel", handleMouseScroll);
      canvasContainer.addEventListener("mousedown", onMouseDown);
      canvasContainer.addEventListener("mousemove", onMouseMove);
      canvasContainer.addEventListener("mouseup", onMouseUp);
      window.addEventListener("resize", resizeListener);
    }

    setFabricCanvas(fabricCanvasInstance);
    setIsFabricActonsReady(true);
    setIsCanvasReady(true);
    return () => {
      // Clean up event listeners on component unmount
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", resizeListener);
      if (undoButton) {
        undoButton.removeEventListener("click", handleUndo);
      }
      if (redoButton) {
        redoButton.removeEventListener("click", handleRedo);
      }
    };
  }, [
    addImageToCanvas,
    selectedSide,
    sidesData,
    updateSelectedObjectsConfig,
    handleUndo,
    handleRedo,
  ]);

  useEffect(() => {
    if (isCanvasReady && fabricCanvas) {
      const Redo = () => {
        console.log("Redo triggered");
        handleRedo();
      };

      const redoButton = document.getElementById("redoButton");
      if (redoButton) {
        redoButton.addEventListener("click", Redo);
      }

      return () => {
        if (redoButton) {
          redoButton.removeEventListener("click", Redo);
        }
      };
    }
  }, [isCanvasReady, fabricCanvas, handleRedo]);

  const discardActiveObjects = useCallback(() => {
    if (!fabricCanvas) {
      return;
    }
    fabricCanvas.discardActiveObject();
    fabricCanvas.renderAll();
  }, [fabricCanvas]);

  function renderIcon(icon: any) {
    // eslint-disable-next-line func-names
    return function (
      ctx: any,
      left: number,
      top: number,
      styleOverride: any,
      fabricObject: any,
    ) {
      const size = 15;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
      saveState();
    };
  }

  const onAddText = useCallback(
    (text: string, options: TextOptions) => {
      const { heading } = options;
      let fontSize = 18;
      switch (heading) {
        default:
        case "h1":
          fontSize = 30;
          break;
        case "h2":
          fontSize = 24;
          break;
        case "h3":
          fontSize = 21;
          break;
        case "h4":
          fontSize = 18;
          break;
        case "h5":
          fontSize = 16;
          break;
        case "h6":
          fontSize = 14;
          break;
      }

      const textObject = new fabric.IText(text, {
        fontFamily: options.fontFamilyId ||
          DefualtSelectedObjectsConfig.fontFamily,
        fill: "#000",
        fontSize: fontSize,
        fontWeight: "normal",
        padding: 5,
        opacity: 1,
        lineHeight: MinLineHeight,
      });

      // Add the text object to the canvas
      (fabricCanvas as Canvas).add(textObject);

      // Center the text object
      textObject.set({
        left: (fabricCanvas?.width ?? 0) / 2 - (textObject.width ?? 0) / 2,
        top: (fabricCanvas?.height ?? 0) / 2 - (textObject.height ?? 0) / 2,
        originX: "left",
        originY: "top",
      });

      // Re-render the canvas to apply the new position
      textObject.setCoords();
      fabricCanvas?.renderAll();
      saveState();
    },
    [fabricCanvas],
  );

  const getSelectedObjects = useCallback(() => {
    if (!fabricCanvas) {
      return [];
    }
    const aObject = (fabricCanvas as Canvas).getActiveObject();
    if (!aObject) {
      return [];
    }
    if (aObject.type === "activeSelection") {
      return (aObject as ActiveSelection).getObjects();
    } else {
      return [aObject];
    }
  }, [fabricCanvas]);

  //   useEffect(() => {
  //   const handleOutsideClick = (event: MouseEvent) => {
  //     const canvasElement = document.getElementById('canvas');
  //     if (canvasElement && !canvasElement.contains(event.target as Node)) {
  //       // Clicked outside the canvas
  //       console.log("Clicked outside canvas");
  //       if (fabricCanvasRef.current) {
  //         fabricCanvasRef.current.discardActiveObject();
  //         fabricCanvasRef.current.requestRenderAll();
  //         setSelectedObjectsConfig(DefualtSelectedObjectsConfig);
  //         selectedElementType(undefined);
  //         setSelectedCategory(undefined);
  //       }
  //     }
  //   };

  //   document.addEventListener('mousedown', handleOutsideClick);

  //   return () => {
  //     document.removeEventListener('mousedown', handleOutsideClick);
  //   };
  // }, [setSelectedObjectsConfig, selectedElementType, setSelectedCategory]);

  const emptySelectedObjects = useCallback(() => {
    // const canvas = fabricCanvasRef.current;
    // if (!canvas) {
    //   console.log("No canvas found");
    //   return;
    // }

    // console.log("Canvas objects before clearing:", canvas.getObjects());

    // // Instead of clearing everything, let's just deselect
    // canvas.discardActiveObject();

    // // Reset states
    // setSelectedObjectsConfig(DefualtSelectedObjectsConfig);
    // selectedElementType(undefined);
    // setSelectedCategory(undefined);

    // // Re-render the canvas
    // canvas.requestRenderAll();

    // console.log("Selection cleared");
  }, [setSelectedObjectsConfig, selectedElementType, setSelectedCategory]);

  const onBold = useCallback(() => {
    if (!fabricCanvas) {
      return;
    }
    const aObject = (fabricCanvas as Canvas).getActiveObject();
    if (!aObject) {
      return;
    }
    if (aObject.type === "activeSelection") {
      const objects: Object[] = (aObject as ActiveSelection).getObjects();
      const isBold = objects.some((obj) => (obj as Text).fontWeight === "bold");
      objects.forEach((obj) => {
        (obj as Text).set("fontWeight", isBold ? "normal" : "bold");
      });
    } else {
      (aObject as Text).set(
        "fontWeight",
        (aObject as Text).fontWeight === "normal" ? "bold" : "normal",
      );
    }
    updateSelectedObjectsConfig();
    (fabricCanvas as Canvas).requestRenderAll();
  }, [fabricCanvas, updateSelectedObjectsConfig]);

  const onItalic = useCallback(() => {
    if (!fabricCanvas) {
      return;
    }
    const aObject = (fabricCanvas as Canvas).getActiveObject();
    if (!aObject) {
      return;
    }
    if (aObject.type === "activeSelection") {
      const objects: Object[] = (aObject as ActiveSelection).getObjects();
      const isItalic = !objects.some(
        (obj) => (obj as Text).fontStyle === "italic",
      );
      objects.forEach((obj) => {
        (obj as Text).set("fontStyle", isItalic ? "normal" : "italic");
      });
    } else {
      (aObject as Text).set(
        "fontStyle",
        (aObject as Text).fontStyle === "normal" ? "italic" : "normal",
      );
    }
    updateSelectedObjectsConfig();
    (fabricCanvas as Canvas).requestRenderAll();
    saveState();
  }, [fabricCanvas, updateSelectedObjectsConfig]);

  const onChangeFontSize = useCallback(
    (increase: boolean) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      const { fontSize } = DefualtSelectedObjectsConfig;
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as Text).set(
            "fontSize",
            increase
              ? (obj as Text).fontSize || fontSize + 1
              : (obj as Text).fontSize || fontSize - 1,
          );
        });
      } else {
        (aObject as Text).set(
          "fontSize",
          increase
            ? ((aObject as Text).fontSize || fontSize) + 1
            : ((aObject as Text).fontSize || fontSize) - 1,
        );
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeFontFamily = useCallback(
    (newFontFamily: string) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as Text).set("fontFamily", newFontFamily);
        });
      } else {
        (aObject as Text).set("fontFamily", newFontFamily);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeLineHeight = useCallback(
    (value: number) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        // const isLineHeight = objects.some((obj) => (obj as Text).lineHeight === MaxLineHeight);
        objects.forEach((obj) => {
          // (obj as Text).set("lineHeight", isLineHeight ? MinLineHeight : MaxLineHeight);
          (obj as Text).set("lineHeight", value);
        });
      } else {
        (aObject as Text).set("lineHeight", value);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeColor = useCallback(
    (newHexColor: string) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as Text).set("fill", newHexColor);
        });
      } else {
        (aObject as Text).set("fill", newHexColor);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeFontAligment = useCallback(
    (aligmentId: string) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as Text).set("textAlign", aligmentId);
        });
      } else {
        (aObject as Text).set("textAlign", aligmentId);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeOpacity = useCallback(
    (opacity: number) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as Text).set("opacity", opacity);
        });
      } else {
        (aObject as Text).set("opacity", opacity);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeBorderColor = useCallback(
    (newHexColor: string) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as Text).set("stroke", newHexColor);
        });
      } else {
        (aObject as Text).set("stroke", newHexColor);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeBorderStyle = useCallback(
    (borderStyle: BorderStyle) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as fabric.Rect).set(
            "strokeDashArray",
            borderStyle === "Dash" ? [9, 2] : [],
          );
        });
      } else {
        (aObject as fabric.Rect).set(
          "strokeDashArray",
          borderStyle === "Dash" ? [9, 2] : [],
        );
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeBorderWight = useCallback(
    (borderWidth: number) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as fabric.Rect).set("strokeWidth", borderWidth);
        });
      } else {
        (aObject as fabric.Rect).set("strokeWidth", borderWidth);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onChangeBorderRadius = useCallback(
    (borderRadius: number) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = (fabricCanvas as Canvas).getActiveObject();
      if (!aObject) {
        return;
      }
      if (aObject.type === "activeSelection") {
        const objects: Object[] = (aObject as ActiveSelection).getObjects();
        objects.forEach((obj) => {
          (obj as fabric.Rect).set("rx", borderRadius);
          (obj as fabric.Rect).set("ry", borderRadius);
        });
      } else {
        (aObject as fabric.Rect).set("rx", borderRadius);
        (aObject as fabric.Rect).set("ry", borderRadius);
      }
      updateSelectedObjectsConfig();
      (fabricCanvas as Canvas).requestRenderAll();
      saveState();
    },
    [fabricCanvas, updateSelectedObjectsConfig],
  );

  const onUploadImage = useCallback(
    (imageBase: string) => {
      if (!fabricCanvas) {
        return;
      }
      const randomKey = Math.random().toString(36).substring(7);
      saveImage(randomKey, imageBase);
      fabric.Image.fromURL(
        imageBase,
        (img) => {
          const canvasWidth = fabricCanvas.getWidth();
          const canvasHeight = fabricCanvas.getHeight();

          // Calculate scale to fit the image within the canvas
          const scaleX = canvasWidth / (img.width ?? 1);
          const scaleY = canvasHeight / (img.height ?? 1);
          const scale = Math.min(scaleX, scaleY, 1); // Use 1 as max to avoid enlarging small images

          // Set the scale of the image
          img.scale(scale);

          // Calculate center position
          const canvasCenterX = canvasWidth / 2;
          const canvasCenterY = canvasHeight / 2;

          // Set the image position to the center
          img.set({
            left: canvasCenterX,
            top: canvasCenterY,
            originX: "center",
            originY: "center",
            crossOrigin: "anonymous",
          });

          // Add image to canvas and render
          fabricCanvas.add(img);
          fabricCanvas.renderAll();

          // Optionally save state if needed
          saveState();
        },
        { crossOrigin: "anonymous" },
      );
    },
    [fabricCanvas],
  );

  const onApplyImage = () => {
    return new Promise((resolve) => {
      if (!fabricCanvas) {
        resolve({
          newSidesData: sidesData,
          newImgsSidesData: imgsSidesData,
          canvasColor,
        });
        return;
      }

      const sideData = (fabricCanvas as Canvas).toJSON();
      const newSidesData = {
        ...sidesData,
        [selectedSide]: sideData,
        EXTRA: sideData,
      };

      // @ts-ignore
      const model = gameManager?.studioSceneManager.selectedModelConfig?.id;
      const isBlackMug = model === "BLACK Mug";
      let bgColor = isBlackMug ? "#000000" : canvasColor;

      // Store original background
      const originalBackgroundColor = fabricCanvas.backgroundColor;

      // First get transparent version
      fabricCanvas.setBackgroundColor(originalBackgroundColor as string, () => {
        const transparentImgData = (fabricCanvas as Canvas).toDataURL({
          format: "png",
          quality: 1,
          multiplier: 2,
          enableRetinaScaling: true,
        });

        // Then get colored version for EXTRA
        fabricCanvas.setBackgroundColor(canvasColor, () => {
          const coloredImgData = (fabricCanvas as Canvas).toDataURL({
            format: "png",
            quality: 1,
            multiplier: 2,
            enableRetinaScaling: true,
          });

          // Restore original background
          fabricCanvas.setBackgroundColor(bgColor, () => {
            fabricCanvas.renderAll();
          });

          const newImgsSidesData = {
            ...imgsSidesData,
            [selectedSide]: transparentImgData,
            EXTRA: coloredImgData,
          };

          setSideData((prev) => ({ ...prev, [selectedSide]: sideData }));
          setimgsSideData((prev) => ({ ...prev, [selectedSide]: transparentImgData }));
          setCanvasColor(bgColor);

          if (gameManager?.studioSceneManager) {
            // @ts-ignore
            gameManager.studioSceneManager.applyTexture(transparentImgData, true, bgColor);
          }

          resolve({ newSidesData, newImgsSidesData, bgColor });
        });
      });
    });
  };

  useEffect(() => {
    console.log("keeping track of canvas: ", fabricCanvas);
  }, [fabricCanvas]);

  const applyImageFromBE = (json: any) => {
    setSideData(json.sidesData);
    onInit2DEditor();
  };

  const onSubmitData = async (id: any, token: string, redirect = true, design?: any): Promise<void> => {
    try {
      // Set canvas background to transparent for no background in design
      fabricCanvas?.setBackgroundColor("transparent", () => { });

      // Remove cached design if exists
      localStorage.removeItem("temp_design");

      setSelectedSide(selectedSide);

      // Apply image changes
      // @ts-ignore
      const { newSidesData, newImgsSidesData } = await onApplyImage();

      // Extract token from URL
      const { href } = window.location;
      token = href.split("token=")[1];

      const svg = (fabricCanvas as Canvas).toSVG();
      const json = { sidesData: newSidesData, imgsSidesData: newImgsSidesData };

      const payload = {
        customerDesign: {
          productId: id,
          json: JSON.stringify(json),
        },
      };

      // Fetch existing designs
      const oldDesignsResponse = await fetch(
        "https://server.ownly.net/rest/en/V1/productdesign",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const oldDesignsData = await oldDesignsResponse.json();

      // Check if the design needs an update
      const existingDesign = oldDesignsData.find((design: any) => design.product_id === id);
      const isUpdate = !!existingDesign;

      const url = isUpdate
        ? `https://server.ownly.net/rest/V1/productdesign/${existingDesign.design_id}`
        : "https://server.ownly.net/rest/V1/productdesign/save";
      const method = isUpdate ? "PUT" : "POST";

      // Submit design (update or save)
      setLoading(true);
      const response = await fetch(url, {
        method,
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const responseData = await response.json();
      setLoading(false);

      if (response.status === 200) {
        // Restore original canvas background color
        fabricCanvas?.setBackgroundColor(canvasColor, () => { });
        if (redirect) {
          window.location.href = "https://ownly.net/my-designs";
        }
      } else {
        setError(responseData.message);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error in onSubmitData:", error);
      setError("An unexpected error occurred.");
      setOpen(true);
    } finally {
      saveState(); // Ensure state is saved regardless of success or failure
    }
  };

  const saveToLocalAndRedirect = async () => {
    // @ts-ignore
    const { newSidesData, newImgsSidesData } = await onApplyImage();
    const json = { sidesData: newSidesData, imgsSidesData: newImgsSidesData };
    localStorage.setItem("temp_design", JSON.stringify(json));
    window.location.href = `https://ownly.net/login?redirect=${window.location.href}`;
  }

  const onDraw = useCallback(
    (drawOptions?: DrawOptions) => {
      if (!fabricCanvas) {
        return;
      }
      fabricCanvas.isDrawingMode = true;

      switch (drawOptions?.type) {
        case "Pencil":
        case "Circle":
        default:
          // Customize the marker brush
          fabricCanvas.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas);
          fabricCanvas.freeDrawingBrush.color = drawOptions?.color || "red"; // Set brush color
          fabricCanvas.freeDrawingBrush.width = drawOptions?.width || 2;
          fabricCanvas.freeDrawingBrush.decimate = 5; // Adjusts points density for smoother curves
          break;
        case "Marker":
          // @ts-ignore
          fabricCanvas.freeDrawingBrush = new fabric.MarkerBrush(fabricCanvas, {
            width: drawOptions.width,
            opacity: 1,
            color: drawOptions.color,
          });
          break;
      }

      saveState();
    },
    [fabricCanvas],
  );

  const isDrawingMode = useCallback(() => {
    if (!fabricCanvas) {
      return false;
    }
    return fabricCanvas.isDrawingMode || false;
  }, [fabricCanvas]);

  const cancelDrawing = useCallback(() => {
    if (!fabricCanvas) {
      return;
    }
    fabricCanvas.isDrawingMode = false;
  }, [fabricCanvas]);

  const getImagesFilters = useCallback(() => {
    let results: {
      id: fabric.IGrayscaleFilter;
      src: string;
      selected: boolean;
    }[] = [];

    if (!fabricCanvas) {
      return results;
    }
    const aObject = getSelectedElement();
    if (!aObject) {
      return results;
    }

    if (aObject instanceof fabric.Image) {
      // const clonedImage = fabric.Image.fromElement(aObject.toObject());
      const filtersMethods = [
        new fabric.Image.filters.Grayscale(),
        new fabric.Image.filters.Blur(),
        new fabric.Image.filters.Sepia(),
        new fabric.Image.filters.Invert(),
        new fabric.Image.filters.Blur({ blur: 0.25 }),
        new fabric.Image.filters.Brightness({ brightness: 0.2 }),
        new fabric.Image.filters.Contrast({ contrast: 0.5 }),
        new fabric.Image.filters.Saturation({ saturation: -0.5 }),
        new fabric.Image.filters.HueRotation({ rotation: 45 }),
        new fabric.Image.filters.Noise({ noise: 100 }),
        new fabric.Image.filters.Pixelate({ blocksize: 5 }),
      ];

      const selectedFilter = aObject.filters && aObject.filters[0];
      aObject.filters = [];
      aObject.applyFilters();
      aObject.cloneAsImage((clone: fabric.Image) => {
        results = filtersMethods.map((filter) => {
          (clone.filters || []).push(filter);
          const base64URL = clone.applyFilters().toDataURL({ format: "png" });
          clone.filters = [];
          clone.applyFilters();
          return {
            id: filter,
            src: base64URL,
            selected: (filter as any)?.type ===
              (selectedFilter && (selectedFilter as any))?.type || false,
          };
        });
        // fabricCanvas.add(clone);
        clone.dispose();
        return results;
      });
      if (selectedFilter) {
        aObject.filters = [selectedFilter];
        aObject.applyFilters();
      }
      return results;
    }

    return results;
  }, [fabricCanvas, getSelectedElement]);

  const applyImageFilter = useCallback(
    (selectedId: fabric.IGrayscaleFilter) => {
      if (!fabricCanvas) {
        return;
      }
      const aObject = getSelectedElement();
      if (!aObject) {
        return;
      }

      if (aObject instanceof fabric.Image) {
        aObject.filters = [];
        (aObject.filters || []).push(selectedId);
        aObject.applyFilters();
        fabricCanvas.renderAll();
      }
      saveState();
    },
    [fabricCanvas, getSelectedElement],
  );

  const removeImageBackground = useCallback(
    // eslint-disable-next-line require-await
    async () => {
      if (!fabricCanvas) {
      }
      setLoading(true);
      const aObject = getSelectedElement();
      if (!aObject) {
        return;
      }
      if (aObject instanceof fabric.Image) {
        const base64 = aObject.getSrc();
        fetch(base64)
          .then((res) => res.blob())
          .then(async (blob) => {
            const formData = new FormData();
            formData.append("image_file", blob);

            const response = await fetch(
              "https://sdk.photoroom.com/v1/segment",
              {
                method: "POST",
                headers: {
                  "x-api-key": X_API_KEY || "",
                },
                body: formData,
              },
            );
            if (response.status !== 200) {
              setLoading(false);
              return;
            }
            const outputBlob = await response.blob();
            let reader = new FileReader();
            reader.readAsDataURL(outputBlob);
            reader.onloadend = () => {
              let base64data = reader.result;
              if (base64data) {
                aObject.setSrc(base64data as string, () => {
                  fabricCanvas?.renderAll(); // Render the canvas to reflect the deselection
                });
                setLoading(false);
              }
            };
          });
      }

      saveState();
    },
    [fabricCanvas, getSelectedElement],
  );

  const drawShapeById = useCallback(
    (shapeId: ShapeTypes) => {
      if (!fabricCanvas) {
        return;
      }

      const canvasCenter = {
        left: fabricCanvas.getWidth() / 2,
        top: fabricCanvas.getHeight() / 2,
      };

      let newShape;
      switch (shapeId) {
        default:
        case "rectangle":
          // Draw a rectangle
          newShape = new fabric.Rect({
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
            rx: 0,
            ry: 0,
          });
          break;
        case "circle":
          // Draw a circle
          newShape = new fabric.Circle({
            left: canvasCenter.left - 80,
            top: canvasCenter.top - 80,
            radius: 80,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
        case "triangle":
          // Draw a triangle
          newShape = new fabric.Triangle({
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
        case "star5":
          newShape = new fabric.Polygon(starPolygonPoints(5, 50, 20, 60), {
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
        case "star4":
          newShape = new fabric.Polygon(starPolygonPoints(4, 50, 20, 0), {
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
        case "star8":
          newShape = new fabric.Polygon(starPolygonPoints(8, 50, 20, 0), {
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
        case "pentagram":
          newShape = new fabric.Polygon(pentagramPoints(5), {
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
        case "hex":
          newShape = new fabric.Polygon(pentagramPoints(6), {
            left: canvasCenter.left - 75,
            top: canvasCenter.top - 75,
            width: 150,
            height: 150,
            fill: "#caccd1",
            stroke: "#8683db",
            strokeWidth: 2,
          });
          break;
      }
      fabricCanvas.add(newShape);
      saveState();
    },
    [fabricCanvas],
  );

  const onSelectFistSide = useCallback(
    (side: keyof typeof SideTypes) => {
      setSelectedSide(side);
      if (gameManager) {
        gameManager.studioSceneManager.changeSide(side);
      }
      setSelectedRenderMode("2DMODE");
      setSelectedCategory(undefined);
      setIsFirstUse(false);
    },
    [gameManager],
  );

  useEffect(() => {
    if (fabricCanvas) {
      fabricCanvas.setBackgroundColor(
        canvasColor,
        fabricCanvas.renderAll.bind(fabricCanvas),
      );
    }
  }, [canvasColor, fabricCanvas]);

  const onChangeCanvasColor = useCallback((color: string) => {
    setCanvasColor(color);
    fabricCanvas?.setBackgroundColor(
      canvasColor,
      fabricCanvas?.renderAll.bind(fabricCanvas),
    );
  }, [fabricCanvas, canvasColor]);

  const onSetRightMenu = useCallback((show: boolean) => {
    setShowRightMenu(show);
  }, []);
  const bottomMenuVisibility = useCallback((menu: BottomMenuType) => {
    // if (!fabricCanvas) {
    //   return;
    // }
    setBottomMenu(menu);
    // fabricCanvas.backgroundColor=color;
  }, []);

  const resizeAndRepositionImage = useCallback(
    (img: fabric.Image, canvas: fabric.Canvas) => {
      const canvasWidth = canvas.getWidth();
      const canvasHeight = canvas.getHeight();

      const scaleX = canvasWidth / img.width!;
      const scaleY = canvasHeight / img.height!;
      const scale = Math.min(scaleX, scaleY);

      img.scale(scale).set({
        left: (canvasWidth - img.width! * scale) / 2,
        top: (canvasHeight - img.height! * scale) / 2,
      });

      img.setCoords();
      canvas.renderAll();
    },
    [],
  );
  const handleResize = useCallback(
    (fabricCanvas: any) => {
      if (!fabricCanvas) {
        return;
      }
      const objects = fabricCanvas.getObjects();
      objects.forEach((obj: any) => {
        if (obj.type === "image") {
          resizeAndRepositionImage(obj as fabric.Image, fabricCanvas);
        }
      });
    },
    [fabricCanvas, resizeAndRepositionImage],
  );

  const onSelectSvgIcon = useCallback(
    (svgString: string) => {
      if (!fabricCanvas) {
        return;
      }

      const fff = fabric.Image.fromURL(
        svgString,
        (img) => {
          // (img as any)['customId'] ='vvvvvvvvvvv';
          (fabricCanvas as Canvas).add(img);
          (fabricCanvas as Canvas).renderAll();
        },
        { scaleX: 2, scaleY: 2 },
      );

      //SVG
      // fabric.loadSVGFromString(svgString,(imgs:fabric.Object[])=>{
      //     console.log("e",imgs)
      //     if(imgs && imgs.length && imgs[0]){
      //       const [img] = imgs;
      //         img.set({
      //           left: 10,
      //           top: 10,
      //           scaleX: 2,
      //           scaleY: 2,
      //           fill:"black",
      //         });
      //         (img as any)['customId'] ='vvvvvvvvvvv'
      //       fabricCanvas.add(img);
      //     }
      // });
    },
    [fabricCanvas],
  );

  return {
    container,
    fabricCanvas,
    undoStack,
    redoStack,
    gameManager,
    selectedObjectsConfig,
    selectedSide,
    elementType,
    selectedCategory,
    bottomMenu,
    selectedSubCategory,
    isFirstUse,
    onSetFirstUse,
    selectedRenderMode,
    canvasColor,
    showRightMenu,
    setShowRightMenu,
    onSetRightMenu,
    onSelectSvgIcon,
    onChangeCanvasColor,
    setBottomMenu,
    bottomMenuVisibility,
    setSelectedRenderMode,
    onSelectFistSide,
    applyImageFromBE,
    isDrawingMode,
    setGameManager,
    onInit2DEditor,
    open,
    setOpen,
    loading,
    setLoading,
    error,
    setError,
    discardActiveObjects,
    selectedElementType,
    getSelectedObjects,
    setSelectedCategory,
    setSelectedSubCategory,
    setSelectedSide,
    removeImageBackground,
    onAddText,
    onBold,
    onItalic,
    onChangeColor,
    onChangeBorderColor,
    onChangeFontSize,
    onChangeFontFamily,
    onChangeLineHeight,
    onChangeFontAligment,
    onChangeBorderStyle,
    onDraw,
    saveState,
    handleUndo,
    handleRedo,
    cancelDrawing,
    getImagesFilters,
    applyImageFilter,
    drawShapeById,
    onChangeBorderWight,
    onChangeBorderRadius,
    onChangeOpacity,
    onUploadImage,
    onApplyImage,
    emptySelectedObjects,
    onSubmitData,
    saveToLocalAndRedirect
  };
};

export default useEditorActions;
