import React, { lazy, useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { renderRoutes, RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { QueryParamProvider } from 'use-query-params';
import { AppPaths } from './types';
// import {ModulesPaths} from 'shared/types/index';
import Layout from './layout';
import WithLayout from 'shared/components/WithLayout';

import Spinner from 'shared/components/Spinner';
import { ModulesPaths } from 'shared/types';
import useEditorActions from './Editor/actions/useEditorActions';
import axios from 'axios';

const Editor = lazy(() => import('./Editor'));
const ErrorPage404 = lazy(() => import('shared/components/mine/ErrorPages/404'));

const routes: RouteConfig[] = [
  {
    path: ModulesPaths.ROOT,
    component: WithLayout, //Security & Header
    routes: [
      {
        path: AppPaths.HOME,
        name: 'Restaurants',
        component: Editor,
      },
      {
        path: '*',
        exact: false,
        name: 'page404',
        component: ErrorPage404,
      },
    ],
  },
];

const Modules = () => {
  const renderedRoutes = renderRoutes(routes);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const { loading } = useEditorActions();

  const loginFromToken = async () => {
    try {
      const res = await axios.get("https://server.ownly.net/rest/en/V1/customers/me", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      console.log(res.data);
    } catch (error: any) {
      console.error(error);
    }
  }

  useEffect(() => {
    loginFromToken();
  }, [])

  if (loading) {
    return <Spinner />
  }
  return (
    <React.Suspense fallback={<Spinner />}>
      <QueryParamProvider ReactRouterRoute={Route}>{renderedRoutes}</QueryParamProvider>
    </React.Suspense>
  );
};

export default Modules;
